import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [fixed, setFixed] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    setScrolled(scrollY > 1);
    setFixed(scrollY > 72);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="header__inner__left">
          <img
            width="150"
            height="64"
            alt=""
            src="https://neveragain.allstatics.com/2019/assets/icon/logo/wondershare-vertical-white.svg"
          />
          <div className="head_img_right_title">
            <ul>
              <li>
                <Link to="/" onClick={toTop}>
                  Video Creativity
                </Link>
              </li>
              <li>
                <Link to="/" onClick={toTop}>
                  Diagram & Graphics
                </Link>
              </li>
              <li>
                <Link to="/" onClick={toTop}>
                  PDF Solutions
                </Link>
              </li>
              <li>
                <Link to="/" onClick={toTop}>
                  Data Management
                </Link>
              </li>
              <li>
                <Link to="/" onClick={toTop}>
                  Explore AI
                </Link>
              </li>
              <li>
                <Link to="/" onClick={toTop}>
                  Business
                </Link>
              </li>

              <li>
                <Link to="/shop" onClick={toTop}>
                  Shop
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={toTop}>
                  Support
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`header__inner ${fixed ? "fixed" : ""} ${
            scrolled ? "scrolled" : ""
          }`}
        >
          <div className="wrp-navi">
            <div className="fixed_txt_img">
              <img
                width="32"
                height="32"
                src="https://neveragain.allstatics.com/2019/assets/icon/logo/mockitt-square.svg"
                alt="mockitt header"
              />
              <strong>Mockitt</strong>
            </div>
            <ul>
              <li>
                <Link to="/" onClick={toTop}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/privacy" onClick={toTop}>
                  Privacy
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={toTop}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="banner_div"></div>
      </div>
    </div>
  );
}

export default Header;
