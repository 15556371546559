import React, { useState } from "react";
import Footer from "./Footer";
import "../css/contact.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";

function Contact() {
  const [help, setHelp] = useState("");
  const [value, setValue] = useState(null);
  const options = countryList().getData();

  const changeHandler = (value) => {
    setValue(value);
  };
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    fullName: "",
    businessEmail: "",
    company: "",
    product: "",
    number: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const errors = {};

    if (!formData.fullName) errors.fullName = "Full Name is required";
    if (!formData.businessEmail)
      errors.businessEmail = "Business Email is required";
    if (!formData.company) errors.company = "Company is required";
    if (!value) errors.country = "Country is required";
    if (!formData.product) errors.product = "Product is required";
    if (!formData.number) errors.number = "Number is required";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    alert("Submit Success!");
    setFormData({
      fullName: "",
      businessEmail: "",
      company: "",
      product: "",
      number: "",
    });
    setHelp("");
    setValue(null);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <div className="header__inner__left">
        <img
          width="150"
          height="64"
          alt=""
          src="https://neveragain.allstatics.com/2019/assets/icon/logo/wondershare-vertical-white.svg"
        />
        <div className="head_img_right_title">
          <ul>
            <li>
              <Link to="/" onClick={toTop}>
                Video Creativity
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Diagram & Graphics
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                PDF Solutions
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Data Management
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Explore AI
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Business
              </Link>
            </li>

            <li>
              <Link to="/shop" onClick={toTop}>
                Shop
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toTop}>
                Support
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="contact_div_all">
        <div className="contact_div_all_inner">
          <div className="contact_inner_box">
            <div className="contact_inner_box_left">
              <div className="share_left_top">
                Wondershare Solutions for Business
              </div>
              <h1 className="share_left_mid">
                Redefine digital experiences with enterprise tech.
              </h1>
              <div className="share_left_third">
                <div className="share_left_third_inner">
                  <div className="with-checked">PDF &amp; eSign Solutions</div>
                </div>
                <div className="share_left_third_inner">
                  <div className="with-checked">
                    Diagram &amp; Graphics Solutions
                  </div>
                </div>
                <div className="share_left_third_inner">
                  <div className="with-checked">
                    Video Editor &amp; Generator Solutions
                  </div>
                </div>
                <div className="share_left_third_inner">
                  <div className="with-checked">Data Management Solutions</div>
                </div>
              </div>
              <div class="share_left_bot">
                <Link to="/shop">
                  <i
                    class="wsc-icon mr-2 wsc-icon-loaded"
                    data-path="https://images.wondershare.com/filmora/filmora13/product-win/shop-cart-icon.svg"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.8598 5.55553C20.5048 5.16109 19.992 4.92443 19.4398 4.92443H5.04276L4.96387 3.97777C4.92443 3.34666 4.56943 2.83389 4.01721 2.55778L2.83389 2.04501C2.51834 1.92668 2.20279 2.04501 2.04501 2.36056C1.92668 2.67612 2.04501 2.99167 2.36056 3.14944L3.50444 3.66222C3.66222 3.7411 3.78055 3.89888 3.78055 4.0961L4.49054 15.6532C4.56943 16.8365 5.63442 17.7832 6.85718 17.7832H19.9526C20.2682 17.7832 20.5443 17.5071 20.5443 17.1915C20.5443 16.876 20.2682 16.5999 19.9526 16.5999H6.89663C6.26552 16.5999 5.75275 16.1265 5.71331 15.5743L5.67386 14.9827L18.6509 13.9966C19.5582 13.9177 20.3076 13.2471 20.3865 12.3399L21.3331 7.09385V7.0544C21.3726 6.50219 21.2148 5.94997 20.8598 5.55553Z"
                        fill="currentcolor"
                      ></path>
                      <path
                        d="M5.2002 20.1105C5.2002 20.4767 5.34564 20.8278 5.60455 21.0867C5.86345 21.3456 6.21459 21.4911 6.58074 21.4911C6.94688 21.4911 7.29802 21.3456 7.55693 21.0867C7.81583 20.8278 7.96128 20.4767 7.96128 20.1105C7.96128 19.7444 7.81583 19.3932 7.55693 19.1343C7.29802 18.8754 6.94688 18.73 6.58074 18.73C6.21459 18.73 5.86345 18.8754 5.60455 19.1343C5.34564 19.3932 5.2002 19.7444 5.2002 20.1105Z"
                        fill="currentcolor"
                      ></path>
                      <path
                        d="M16.2451 20.1105C16.2451 20.4767 16.3906 20.8278 16.6495 21.0867C16.9084 21.3456 17.2595 21.4911 17.6257 21.4911C17.9918 21.4911 18.3429 21.3456 18.6018 21.0867C18.8608 20.8278 19.0062 20.4767 19.0062 20.1105C19.0062 19.7444 18.8608 19.3932 18.6018 19.1343C18.3429 18.8754 17.9918 18.73 17.6257 18.73C17.2595 18.73 16.9084 18.8754 16.6495 19.1343C16.3906 19.3932 16.2451 19.7444 16.2451 20.1105Z"
                        fill="currentcolor"
                      ></path>
                    </svg>
                  </i>
                  Buy Now
                </Link>
              </div>
            </div>

            <div className="contact_inner_box_right">
              <div className="contact_inner_box_right_inner">
                <div className="contact_table_title">
                  Maximize your productivity today.
                </div>
                <form className="pfah-form font-size-small">
                  <div class="row mx-n2">
                    <div class="col-md-6-form-group">
                      <div class="placeholder-container">
                        <input
                          type="text"
                          class="form-control form-control-md"
                          placeholder="Full Name*"
                          name="fullName"
                          value={formData.fullName}
                          onChange={handleChange}
                          required=""
                        />
                        <br />
                        {formErrors.fullName && (
                          <span className="error-message">
                            {formErrors.fullName}
                          </span>
                        )}
                        {/* <span class="placeholder">Full Name </span> */}
                      </div>
                    </div>
                    <div class="col-md-6-form-group">
                      <div class="placeholder-container">
                        <input
                          type="email"
                          class="form-control form-control-md"
                          name="businessEmail"
                          placeholder="Business Email*"
                          value={formData.businessEmail}
                          onChange={handleChange}
                          required=""
                        />
                        <br />
                        {formErrors.businessEmail && (
                          <div className="error-message">
                            {formErrors.businessEmail}
                          </div>
                        )}
                        {/* <span class="placeholder">Business Email </span> */}
                      </div>
                    </div>
                    <div class="col-md-6-form-group">
                      <div class="placeholder-container">
                        <input
                          type="text"
                          class="form-control form-control-md"
                          placeholder="Company*"
                          name="company"
                          value={formData.company}
                          onChange={handleChange}
                          required=""
                        />
                        <br />
                        {formErrors.company && (
                          <div className="error-message">
                            {formErrors.company}
                          </div>
                        )}
                        {/* <span class="placeholder">Company </span> */}
                      </div>
                    </div>
                    <div class="col-md-6-form-group">
                      <div class="placeholder-container">
                        <Select
                          options={options}
                          value={value}
                          onChange={changeHandler}
                          placeholder="Select Country"
                          styles={{ height: "40px" }}
                        />
                        {/* <br /> */}
                        {formErrors.country && (
                          <div className="error-message">
                            {formErrors.country}
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6-form-group">
                      <div class="placeholder-container">
                        <select
                          class="custom-select custom-select-md"
                          name="product"
                          placeholder="Select Products *"
                          required=""
                          value={formData.product}
                          onChange={handleChange}
                        >
                          <option value="" selected="" disabled="">
                            Select Products *
                          </option>
                          <option value="PDFelement">PDFelement</option>
                          <option value="Document Clound">
                            Document Cloud
                          </option>
                          <option value="Filmora">Filmora</option>
                          <option value="Uniconverter">Uniconverter</option>
                          <option value="DemoCreator">DemoCreator</option>
                          <option value="Virbo">Virbo</option>
                          <option value="Presentory">Presentory</option>
                          <option value="EdrawMind">EdrawMind</option>
                          <option value="EdrawMax">EdrawMax</option>
                          <option value="EdrawProj">EdrawProj</option>
                          <option value="Dr.Fone">Dr.Fone</option>
                          <option value="Recoverit">Recoverit</option>
                          <option value="Repairit">Repairit</option>
                          <option value="MobileTrans">MobileTrans</option>
                          <option value="others">Others</option>
                        </select>
                        <br />
                        {formErrors.product && (
                          <div className="error-message">
                            {formErrors.product}
                          </div>
                        )}
                        {/* <span class="placeholder">Select Products </span> */}
                      </div>
                    </div>
                    <div class="col-md-6-form-group">
                      <div class="placeholder-container">
                        <input
                          type="number"
                          class="form-control form-control-md"
                          name="number"
                          placeholder="Number of Users *"
                          value={formData.number}
                          onChange={handleChange}
                          max="10000"
                          min="0"
                          required=""
                        />
                        <br />
                        {formErrors.number && (
                          <div className="error-message">
                            {formErrors.number}
                          </div>
                        )}
                        {/* <span class="placeholder">Number of Users </span> */}
                      </div>
                    </div>
                    <div class="col-12-textarea">
                      <textarea
                        class="form-control form-control-md"
                        rows="3"
                        placeholder="How can we help? "
                        name="help"
                        value={help}
                        onChange={(e) => setHelp(e.target.value)}
                      ></textarea>
                    </div>

                    <div class="col-12-px-2-button">
                      <button type="submit" onClick={handleFormSubmit}>
                        Contact Our Sales Team
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
