import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Privacy() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <div className="header__inner__left">
        <img
          width="150"
          height="64"
          alt=""
          src="https://neveragain.allstatics.com/2019/assets/icon/logo/wondershare-vertical-white.svg"
        />
        <div className="head_img_right_title">
          <ul>
            <li>
              <Link to="/" onClick={toTop}>
                Video Creativity
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Diagram & Graphics
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                PDF Solutions
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Data Management
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Explore AI
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Business
              </Link>
            </li>

            <li>
              <Link to="/shop" onClick={toTop}>
                Shop
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toTop}>
                Support
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="privacy_box">
        <h1>Wondershare Privacy Policy</h1>
        <div className="privacy_inner_box">
          <div className="privacy_inner_txt_div">
            <p>
              <strong>1. Data Controller and Representative</strong>
            </p>
            <p>
              {" "}
              This Privacy Policy describes the privacy practices of
              Wondershare’s Sites and Products and anywhere we display or
              reference this policy. Through our Sites and Products, Wondershare
              and its affiliated entities (collectively, "Wondershare", "us," or
              "we") implement the processing of information that can be used to
              identify you ("Personal Data") listed below. Wondershare is
              committed to protecting your Personal Data. We understand that the
              security of your Personal Data is important. We provide reasonable
              administrative, technical, and physical security controls to
              protect your Personal Data. We will only collect and use
              information from you as described in this Privacy Policy. We will
              not sell your data to any third parties without your expresee
              consent.{" "}
            </p>
            <p>
              {" "}
              The data controller is Wondershare Technology Group Co., LTD and
              its affiliated companies(including but not limited to Wondershare
              Technology Co., LTD, Wondershare Global Limited, Vbroadcast
              Limited,WONSON GLOBAL ENTERPRISES INC), West of Donghuan Road,
              North of 1-4 Road, South of 1-3 Road, Room No.2, Floor 6, Building
              No.8 of East of Liuwu Building, Lhasa, China. Privacy-related
              questions can be directed to rain@yaiyah.xyz.{" "}
            </p>
            <p>
              {" "}
              Wondershare has appointed an EU representative in accordance with
              the Regulation No. 2016/679 on the protection of natural persons
              with regard to the processing of Personal Data (the “GDPR”). You
              can contact the designated EU representative of Wondershare by
              reaching rain@yaiyah.xyz.{" "}
            </p>
            <p>
              {" "}
              Please read carefully the following privacy policy which provides
              you, as the User of the Sites and/or Products (the “User” or
              “You”), with the relevant information regarding the various
              processing of Personal Data implemented by Wondershare.{" "}
            </p>
            <p>
              <strong>
                2. Personal Data Processing Implemented by Wondershare
              </strong>
            </p>
            <p>
              {" "}
              “Personal Data” means any information relating to an identified or
              identifiable natural person (“data subject”); an identifiable
              natural person is one who can be identified, directly or
              indirectly, in particular by reference to an identifier such as a
              name, an identification number, location data, an online
              identifier or to one or more factors specific to the physical,
              physiological, genetic, mental, economic, cultural or social
              identity of that natural person. Depending on how you interact
              with us, we process different kinds of data and in different ways.
              Some data is automatically processed if you visit our Sites or use
              our Products. Others data is only processed if you actively submit
              it to us (like using our web forms to get in touch with us or
              uploading content to our Sites).{" "}
            </p>
            <p>
              <strong>2.1 Data we process if you visit our Sites:</strong>
            </p>
            <p>
              When the User visits our Sites, Wondershare implements a
              processing of Personal Data as follows:
            </p>
            <p>2.1.1 Personal Data we collect if you visit our Sites</p>
            <p>
              - Date and time of the visit and the duration of use of the Site;
              <br />
              - Information about how you visit our Sites (including the webpage
              that led you to a Wondershare Site, the search terms entered into
              a search engine which led you to a Wondershare Sites);
              <br />
              - Your operations and clicks to our Sites;
              <br />
              - Date and time of the visit and the duration of use of the Sites;
              <br />
              -Information about your device (type of device, browser type and
              version, as well as settings, installed plug-ins, operating
              system).
              <br />
              The aforementioned Personal Data is referred to as Website Usage
              Data.
            </p>
            <p>2.1.2 Purposes of Processing</p>
            <p>
              {" "}
              We process Website Usage Data to allow you to surf the Sites and
              to ensure its functionality. We also process Website Usage Data to
              perform analyses on the performance of the Sites, to continuously
              improve the Sites and correct errors, to ensure IT security and
              operation of our systems, as well as to prevent or uncover abuse.
              We further process this data in aggregated or pseudonymized form
              to analyze trends, administer the Sites, track users' movements
              around the Sites, and to gather demographic information about our
              user base as a whole or to improve marketing (including serving
              you personalized, interest-based ads) as well as the user
              experience on our Site and guaranteeing the safety of our IT
              systems.{" "}
            </p>
            <p>
              <strong>2.2 Data we process only if you submit it to us:</strong>
            </p>
            <p>
              2.2.1 Personal Data we collect if you submit to us through the
              data collection form in the purchase process or other processes:
            </p>
            <p>
              - Account Data: On some of our Sites, you can register for an
              account with your e-mail address and a password you create. If you
              register one of our Sites or Products, you will automatically
              create an account you can use to manage your licensed Products.
              Usually you need to provide your Name, Gender, Age, Image, Email
              address, Address, Country, Postal code, Phone number, Company name
              and industry you work for to create your account;
              <br />
              -Transaction Data: Billing information, such as your name, email
              address, billing address, your business affiliation (company or
              organization name). Type of Product you purchase, Amount you pay
              in your currency, Subscription ID, Subscription-related URLs (such
              as purchase confirmation page URL, subscription cancelation URL,
              invoice PDF URL);
              <br />
              -Comment Data;
              <br />
              -Content of customer support communications;
              <br />
              -Other Data you voluntarily provide to us.
            </p>
            <p>2.2.2 Purposes of Processing</p>
            <p>
              {" "}
              We use Account Data to help you to create an account (WSID) and
              keep a record of your product license (that is, authenticating and
              periodically verifying your licensing rights in the product for
              anti-piracy purposes) and to enable the provision of maintenance
              or support services to you. We will also use this information to
              provide in-product messaging with information about products,
              features, or services offered by Wondershare.{" "}
            </p>
            <p>
              We will use Transaction Data to complete your order, for billing
              purposes, and for internal accounting and charge back management
              purpose.
            </p>
            <p>
              {" "}
              We will use the comment data to improve its products and provide
              the consumers and potential consumers with a better and
              transparent information about the Wondershare products.
              Wondershare may use the comments (with your relevant information)
              you post for marketing purpose in order for others to better
              understand and use our products.{" "}
            </p>
            <p>
              In order to provide the Products you purchased to you, or perform
              the contract between you and Wondershare.
            </p>
            <p>
              {" "}
              We do not collect or process the data about your payment
              instruments such as credit card numbers, banking account numbers
              or Pay Pal accounts. This information is collected, processed and
              stored by our qualified payment processors.{" "}
            </p>
            <p>
              {" "}
              From time to time, we may contact our customers directly by mail,
              email, or telephone to inform you about upgrades, new products,
              promotions, or special offers that you told us you were interested
              in receiving (including our newsletters). The subscription to
              Wondershare’s newsletters and the related provision of Personal
              Data is made on a voluntary basis so that the processing of the
              collected Personal Data relies on the User’s consent. If you want
              to cancel the aforementioned purpose, you can contact us or
              operate through your account or our forms on our Sites.{" "}
            </p>
            <p>
              <strong>2.3 Data we process if you use our Products:</strong>
            </p>
            <p>2.3.1 Personal Data we collect if you use our Products:</p>
            <p>
              We have different kinds of products and different products may
              collect different data. Generally, the data we collect are limited
              to:
            </p>
            <p>
              - your product serial number;
              <br />
              - Product Usage Data. We collect information about the products
              which you use and how you use them, including but not limited to
              Launch data, Feature usage, Page clicks, Sign-in information;
              <br />
              - Product Installation Data. Your local device information,
              including but not limited to your device information, device
              hardware, operating system, other software on your system,
              Username, your Wondershare ID, location, IP address, operating log
              information, the size and format of the files you are working
              with;
              <br />
              - Crash data and the information of the files you are working
              with. If the User meets with a crash problem, we will enquire if
              the User agrees to send the original document to Wondershare. Only
              if the User agrees to send Wondershare the original document,
              Wondershare can have access to the original document;
              <br />
              - For our Famisafe product, we usually collect information on the
              device which has installed Famisafe. The information may include
              device model, system version and language, User’s submitted
              information, installed applications, browser record, keyword (for
              early warning purpose), driving information, etc;
              <br />
              - Wondershare InClowdz uses OAuth authentication to configure
              cloud storage services for the application on your behalf, which
              may include viewing and managing your Google cloud service storage
              files such as photo albums, media libraries, shared albums, etc.
              InClowdz does not store any of your files. InClowdz only transfers
              and processes information based on valid user requests to access
              or transfer files. InClowdz complies with a complete list of
              security standards defined by Google. You can find more
              information about Google security on the Google Cloud Platform
              Website;
              <br />
              - Wondershare Drive is designed as a simple and personalized way
              for you to store your files, documents, photos, and so on (“Your
              Stuff”), therefore, except the general information listed above,
              we also collect Your Stuff you choose to store on our Wondershare
              Drive platform. For our Wondershare Drive product, Your Stuff on
              our Wondershare Drive platform will only be used to provide the
              Wondershare Drive Service to you and will not be used for any
              other purpose.
              <br />- For our Wondershare Virbo product, after you purchase
              subscribe and purchase our AI avatar customization services and
              voice cloning services (collectively,“Customization Services”), we
              will collect and process personal data containing your or the
              designated model's biological features, such as images and voices,
              that you voluntarily submit and provide to us, based on the need
              to provide you with Customization Services, with your affirmative
              consent.
            </p>
            <p>2.3.2 Purposes of Processing</p>
            <p>
              {" "}
              We process Product Usage Data and Product Installation Data so
              that we can facilitate, evaluate, and verify your use of our
              products and services. We will also use the Product Usage Data for
              our own internal statistical and analytical purposes, and to
              evaluate and enhance users' experience of the Products by
              identifying customer preferences and analyzing crash data. If you
              want to cancel the aforementioned purpose, you can contact us or
              operate through your account or our forms on our Sites.{" "}
            </p>
            <p>
              In order to provide the Products you purchased to you, or perform
              the contract between you and Wondershare.
            </p>
            <p>In order to provide customer service or support.</p>
            <p>
              {" "}
              In order to show you ads for Wondershare products and services
              that we think may interest you. If you want to cancel the
              aforementioned purpose, you can contact us or operate through your
              account or our forms on our Sites.{" "}
            </p>
            <p>
              <strong>3. Our Legal Basis for Processing</strong>
            </p>
            <p>
              {" "}
              When processing your Personal Data, we usually rely on your
              consent. However, under some circumstances, we may process your
              Personal Data without getting your prior consent, such
              circumstances may include: (a) processing is necessary for the
              performance of a contract to which you(the data subject) are party
              or in order to take steps at the request of the data subject prior
              to entering into a contract; (b) processing is necessary for
              compliance with a legal obligation to which Wondershare (the
              controller) is subject; (c) processing is necessary in order to
              protect the vital interests of the data subject or of another
              natural person; (d) processing is necessary for the performance of
              a task carried out in the public interest or in the exercise of
              official authority vested in the controller; (e) processing is
              necessary for the purposes of the legitimate interests pursued by
              the controller or by a third party, except where such interests
              are overridden by the interests or fundamental rights and freedoms
              of the data subject which require protection of Personal Data, in
              particular where the data subject is a child.{" "}
            </p>
            <p>
              If we rely on your consent to process your Personal Data, you have
              the right to withdraw your consent at any time.
            </p>
            <p>
              <strong>4. Recipients of Your Personal Data</strong>
            </p>
            <p>
              We treat your Personal Data with care and confidentially and will
              only pass it on to third parties to the extent described below and
              not beyond. We do not share, sell, rent, or trade Personal Data
              with third parties for their promotional purposes without your
              prior consent.
              <br />
              - Affiliated Companies: Wondershare is a global company, with
              subsidiaries worldwide, including in China, Canada, Japan,
              Singapore, South Korea, Hong Kong SAR. We may share data with our
              subsidiaries in the ordinary course of our business, in order to
              provide our product, technical support, customer services to you.
              <br />
              - Service Providers: We may share your Personal Data with
              companies that help us to run our business by processing Personal
              Data on behalf of us for the purposes identified above. Such
              companies include payment processing companies which can help us
              process transactions, the parties providing our third-party
              cookies and tracking tools which may send tracking data or
              analysis report and to Wondershare，email delivery service
              providers which can help us deliver promotion emails to you,
              server companies we hire to provide service to you, charge back
              management service providers, fraud monitoring and prevention
              providers, social medias, algorithm technology service provider,
              third party online forums, and other marketing platforms and
              service providers.
              <br />
              In addition, Wondershare may disclose the User’s Personal Data to:
              <br />
              - Public Authorities: if Wondershare is under a duty to disclose
              or share such Personal Data in order to comply with any legal
              obligation, or in order to protect the rights, property or safety
              of its business, its customers or others; and
              <br />- Business Transactions: to successors in title or
              replacement operators of all or part of Wondershare’s respective
              businesses.
            </p>
            <p>
              <strong>
                5. Transfer of Personal Data Across National Borders
              </strong>
            </p>
            <p>
              {" "}
              The main locations where we process your Personal Data include the
              US, EU, China, but we may also transfer Personal Data to other
              countries in the world where our Products are available. We carry
              out these transfers across national boarder in compliance with
              applicable laws – for example, by putting data transfer
              agreements(such as the Standard Contractual Clauses required by
              GDPR) in place to help protect your Personal Data.{" "}
            </p>
            <p>
              {" "}
              The collected Personal Data may be transferred by Wondershare to
              recipients which are located outside the EU and such destinations
              may not have laws which protect the User’s Personal Data to the
              same extent as in the EU. Wondershare ensures that the User’s
              Personal Data processed by Wondershare or by its suppliers and
              partners operating outside the EU are treated securely and are
              protected against unauthorized access, loss or destruction,
              unlawful processing and any processing which is inconsistent with
              the purposes set out in this privacy policy.{" "}
            </p>
            <p>
              Please find below the relevant information on transfer across
              national borders:
            </p>
            <p>
              - Wondershare may transfer your payment information to payment
              processing companies outside the EU to process transactions;
              <br />
              - Wondershare may use third party cookies and tracking tools (such
              as AdWords, Bing, bugsplat, Criteo, disqus, Facebook, FireBase,
              Flurry, Google Analytics, Hotjar, LinkedIn, Pardot, Quora) to
              track the information on how Users use the Sites and products,
              such Sites usage data and product usage data may be sent to and
              stored in third party servers located outside the EU. Such
              third-party cookies and tracking tools may send the tracking data
              or analysis report to Wondershare for our above-mentioned purpose.
              Most of the third-party cookies and tracking tools process your
              Personal Data anonymously;
              <br />
              - Wondershare may transfer your email address and other necessary
              information such as your name to email delivery service providers
              outside the EU to deliver promotion emails to you;
              <br />
              - Wondershare may store the Personal Data on the servers leased
              from server service providers;
              <br />
              - Wondershare may transfer your relevant Personal Data to
              marketing platforms and service providers outside the EU to
              complete relevant service;
              <br />- Wondershare may transfer your relevant Personal Data to
              fraud monitoring and prevention providers outside the EU to
              complete relevant service.
            </p>
            <p>
              <strong>6. Duration of the storage</strong>
            </p>
            <p>
              {" "}
              Wondershare will store the collected Personal Data for the
              duration necessary for the fulfilment of our contractual
              relationship or legal obligations and permitted by applicable
              legislation. Beyond this duration, we will delete the Personal
              Data according to applicable legislation. in order for Wondershare
              to be able to demonstrate the existence of a right or a contract
              or a legal obligation, the relevant Personal Data will be stored
              through intermediary archives for a duration that will not exceed
              what is strictly necessary regarding the purpose of the storage
              and in accordance with the applicable legislation. Regarding
              banking information, said information will be deleted once the
              payment has been processed and has become effective. Withdrawal
              time period could additionally apply. The number and the
              expiration date of the credit card number (not the CVV security
              code) could be stored through intermediary archives for evidence
              purposes in case of a claim regarding the payment, for the
              duration provided by applicable legislation.{" "}
            </p>
            <p>
              <strong>7. Cookies and tracking tools</strong>
            </p>
            <p>
              {" "}
              Wondershare implements a number of industry standard
              information-tracking tools to collect information about your use
              with our Sites and Products. When the User consults the Sites
              and/or the Products, Wondershare implements cookies on the User’s
              device. The third-party tracking tools are listed as blow, but it
              doesn’t necessarily mean each of our Sites or Products use all the
              tracking tools listed below.{" "}
            </p>
            <div class="mb-5">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="8"
                class="table table-bordered"
              >
                <thead class="bg-primary text-white">
                  <tr>
                    <td width="10%">
                      <strong>No.</strong>
                    </td>
                    <td width="25%">
                      <strong>
                        Name and link of third-party tracking tools
                      </strong>
                    </td>
                    <td width="25%">
                      <strong>Data collected by tracking tools</strong>
                    </td>
                    <td width="25%">
                      <strong>Purpose of data collection</strong>
                    </td>
                    <td width="15%">
                      <strong>Used on Website or product</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      Adwords
                      <br />(
                      <Link
                        to="https://policies.google.com/"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                      >
                        https://policies.google.com/
                      </Link>
                      )
                      <br />
                      Bing
                      <br />(
                      <Link
                        to="https://privacy.microsoft.com/"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                      >
                        https://privacy.microsoft.com/
                      </Link>
                      )
                      <br />
                      Criteo
                      <br />(
                      <Link
                        to="https://www.criteo.com/privacy/"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_3"
                      >
                        https://www.criteo.com/privacy/
                      </Link>
                      )
                      <br />
                      Facebook
                      <br />(
                      <Link
                        to="https://www.facebook.com/privacy/explanation/"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_4"
                      >
                        https://www.facebook.com/privacy/explanation/
                      </Link>
                      )
                      <br />
                      LinkedIn
                      <br />(
                      <Link
                        to="https://www.linkedin.com/legal/privacy-policy"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_5"
                      >
                        https://www.linkedin.com/legal/privacy-policy
                      </Link>
                      )
                      <br />
                      Quora
                      <br />(
                      <Link
                        to="https://www.quora.com/about/privacy"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_6"
                      >
                        https://www.quora.com/about/privacy
                      </Link>
                      )
                    </td>
                    <td>Website usage data</td>
                    <td>targeting and advertising purpose</td>
                    <td>Website</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      FireBase
                      <br />(
                      <Link
                        to="https://policies.google.com/privacy"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_7"
                      >
                        https://policies.google.com/privacy
                      </Link>
                      )
                      <br />
                      Flurry
                      <br />(
                      <Link
                        to="https://legal.yahoo.com/us/en/yahoo/privacy/index.html"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_8"
                      >
                        https://legal.yahoo.com/us/en/yahoo/privacy/index.html
                      </Link>
                      )
                    </td>
                    <td>
                      local device data
                      <br />
                      product usage data
                      <br />
                      data submitted by the User
                    </td>
                    <td>
                      better understand how the products work and crash and make
                      improvements.
                    </td>
                    <td>product</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      Google Analytics
                      <br />(
                      <Link
                        to="https://policies.google.cn/privacy"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_9"
                      >
                        https://policies.google.cn/privacy
                      </Link>
                      )
                      <br />
                      Adjust SDK
                      <br />(
                      <Link
                        to="https://www.adjust.com/terms/privacy-policy/"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_10"
                      >
                        https://www.adjust.com/terms/privacy-policy/
                      </Link>
                      )
                      <br />
                      AppsFlyer SDK
                      <br />(
                      <Link
                        to="https://www.appsflyer.com/legal/privacy-policy/"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_11"
                      >
                        https://www.appsflyer.com/legal/privacy-policy/
                      </Link>
                      )
                    </td>
                    <td>
                      Website usage data
                      <br />
                      product usage data
                      <br />
                      local device data
                    </td>
                    <td>
                      better understand how the Website and product work, and
                      make improvements
                    </td>
                    <td>
                      Website
                      <br />
                      product
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      Bugsplat
                      <br />(
                      <Link
                        to="https://docs.bugsplat.com/introduction/production/security-privacy-and-compliance/privacy-policy"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_12"
                      >
                        {" "}
                        https://docs.bugsplat.com/introduction/production/security-privacy-and-compliance/privacy-policy{" "}
                      </Link>
                      )
                      <br />
                      Tencent bugly
                      <br />(
                      <Link
                        to="https://privacy.qq.com/"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_13"
                      >
                        https://privacy.qq.com/
                      </Link>
                      )
                    </td>
                    <td>crash data</td>
                    <td>
                      better understand how the product crash, and make
                      improvements
                    </td>
                    <td>product</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>
                      Disqus
                      <br />(
                      <Link
                        to="https://help.disqus.com/en/articles/1717103-disqus-privacy-policy"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_14"
                      >
                        https://help.disqus.com/en/articles/1717103-disqus-privacy-policy
                      </Link>
                      )
                    </td>
                    <td>User’s comments to Product and Website</td>
                    <td>better display and manage User’s comments</td>
                    <td>Website</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>
                      Hotjar
                      <br />(
                      <Link
                        to="https://www.hotjar.com/legal/policies/privacy/"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_15"
                      >
                        https://www.hotjar.com/legal/policies/privacy/
                      </Link>
                      )
                    </td>
                    <td>Website usage data</td>
                    <td>
                      better understand how the Websites work and make
                      improvements
                    </td>
                    <td>Website</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>
                      Pardot
                      <br />(
                      <Link
                        to="https://www.salesforce.com/ap/company/privacy/"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_16"
                      >
                        https://www.salesforce.com/ap/company/privacy/
                      </Link>
                      )
                    </td>
                    <td>
                      Inquiry information and other data submitted by the User.
                    </td>
                    <td>
                      In order for Wondershare to contact Users to follow up the
                      inquiry.
                    </td>
                    <td>Website</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>
                      Sense Time SDK
                      <br />(
                      <Link
                        to="https://www.sensetime.com/cn/clause"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_17"
                      >
                        https://www.sensetime.com/cn/clause
                      </Link>
                      )
                    </td>
                    <td>Camera data</td>
                    <td>To realize product function</td>
                    <td>Product</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>
                      JPUSH SDK
                      <br />(
                      <Link
                        to="https://www.jiguang.cn/license/privacy"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_18"
                      >
                        https://www.jiguang.cn/license/privacy
                      </Link>
                      )
                    </td>
                    <td>local device data</td>
                    <td>Push messages</td>
                    <td>Product</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>
                      Sensors Data SDK
                      <br />(
                      <Link
                        to="https://www.sensorsdata.cn/compliance/privacy.html"
                        style={{
                          color: "#006DFF",
                          textDecoration: "underline",
                        }}
                        ga360location="content_2_buttonLink_19"
                      >
                        https://www.sensorsdata.cn/compliance/privacy.html
                      </Link>
                      )
                    </td>
                    <td>product usage data</td>
                    <td>
                      better understand how the product work, and make
                      improvements
                    </td>
                    <td>Product</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              For additional information on cookies and tracking tools, please
              read Wondershare’s
              <Link
                to="/privacy"
                style={{
                  color: "#006DFF",
                  textDecoration: "underline",
                }}
              >
                cookies policy
              </Link>
            </p>
            <p>
              <strong>8. Third Party Services</strong>
            </p>
            <p>
              {" "}
              Some of our products may use the Third-Party services, such as
              Wondershare Virbo, Wondershare DemoCreator, KwiCut use YouTube API
              Services, which enable end users to directly upload videos to
              YouTube from the API client, Wondershare PDFelement use Google API
              Services, which enable end users to read, modify, and save files
              from the API client, KwiCut use Microsoft Copilot, which enable
              users to use GPT Service. and Wondershare Filmora use OpenAI API
              Service, which enable end users to use ChatGPT. When Third-Party
              Services are enabled by a Customer or an Authorized User,
              Wondershare may share Information with Third-Party Services. Such
              as the user's audio, video and etc. Third-Party Services are not
              owned or controlled by Wondershare and third parties that have
              been granted access to Information may have their own policies and
              practices for its collection, use, and sharing. Please check the
              permissions, privacy settings, and notices for these Third-Party
              Services or contact the provider for any questions。{" "}
            </p>
            <p>
              <strong>9. User’s Rights</strong>
            </p>
            <p>
              {" "}
              To the extent required by the law of your jurisdiction, you may
              have below rights. Before you exercise below rights, you may
              consult your legal counsel if the laws of your jurisdiction
              stipulate below rights. Residents in the EU have below rights
              according to GDPR.{" "}
            </p>
            <div class="mb-5">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="8"
                class="table table-bordered"
              >
                <thead class="bg-primary text-white">
                  <tr>
                    <td align="center" width="25%">
                      <strong>Rights</strong>
                    </td>
                    <td align="center" width="75%">
                      <strong>Content</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>The right to be informed</td>
                    <td>
                      {" "}
                      The User has the right to be provided with clear,
                      transparent and easily understandable information about
                      how Wondershare collects and uses Personal Data and its
                      rights. This is the reason why Wondershare is providing
                      the User with the information in this privacy policy.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>The right of access</td>
                    <td>
                      The User has the right to obtain access to its Personal
                      Data (if Wondershare is processing it), and other certain
                      information (similar to that provided in this privacy
                      policy).
                    </td>
                  </tr>
                  <tr>
                    <td>The right to rectification</td>
                    <td>
                      The User is entitled to have its Personal Data corrected
                      if it’s inaccurate or incomplete.
                    </td>
                  </tr>
                  <tr>
                    <td>The right to erasure</td>
                    <td>
                      {" "}
                      This ‘the right to be forgotten’ enables the User to
                      request the deletion or removal of its Personal Data where
                      there’s no compelling reason for Wondershare to keep using
                      it. This is not a general right to erasure; there are
                      exceptions.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>The right to restrict processing</td>
                    <td>
                      Under certain circumstances, the User has the rights to
                      ‘block’ or suppress further use of its Personal Data.
                    </td>
                  </tr>
                  <tr>
                    <td>The right to data portability</td>
                    <td>
                      The User has the rights to receive its Personal Data
                      provided to Wondershare in a structured, commonly used and
                      machine readable format and has the right to transmit
                      those data to another controller.
                    </td>
                  </tr>
                  <tr>
                    <td>The right to object to processing</td>
                    <td>
                      The User has the right to object, on grounds relating to
                      its particular situation, at any time, to the processing
                      of its Personal Data.
                    </td>
                  </tr>
                  <tr>
                    <td>The right to lodge a complaint</td>
                    <td>
                      The User has the right to lodge a complaint about the way
                      Wondershare handles or processes its Personal Data with
                      its national supervisory authority (in France, the CNIL).
                    </td>
                  </tr>
                  <tr>
                    <td>The right to withdraw consent</td>
                    <td>
                      {" "}
                      If the user has given its consent for a specific
                      processing of its Personal Data implemented by
                      Wondershare, the User has the right to withdraw its
                      consent at any time. In case the User does so, it does not
                      mean that anything Wondershare has done with the User’s
                      Personal Data with its consent up to that point is
                      unlawful).{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>The right to define instructions</td>
                    <td>
                      The User has the right to define general or specific
                      instructions regarding storage, deletion and use of its
                      Personal Data after death.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              If you want to exercise your rights aforementioned, you can
              contact us directly or operate through your account or our forms
              on our Sites, we will actively cooperate with you and provide
              assistance to you.
            </p>
            <p>
              {" "}
              However, to register with Wondershare, to create an Wondershare
              account, and to use some Wondershare Sites, Products, the
              provision of some information including but not limited to the
              email address is mandatory: if relevant information is not
              provided, then we will not be able to administer an Wondershare
              account to you, or provide you with the Sites, products or
              services requested. Other provision of your information is
              optional. Providing optional information will help us offer you a
              better experience, such as more personalized or tailored content
              or offerings.{" "}
            </p>
            <p>
              <strong>10. Collecting Information from Children</strong>
            </p>
            <p>
              {" "}
              Wondershare's Sites and products are not intended for use by
              children under the age of 16 and we do not intentionally collect
              any information from users under the age of 16. If you do not meet
              applicable age requirements, please do not use those Sites and
              products. If a parent or guardian becomes aware that their child
              has provided Wondershare with Personal Data or that we have
              otherwise inadvertently collected this information, contact us at
              rain@yaiyah.xyz, so that we may delete the information from our
              servers.{" "}
            </p>
            <p>
              <strong>11. Revision of this Privacy Policy</strong>
            </p>
            <p>
              {" "}
              Occasionally, we may change this privacy policy (or other
              documents related to privacy policy) to allow Wondershare to use
              or share your Personal Data in a different way. If we do, the
              links to the policy on our Sites (which are generally found in the
              footer of the Sites) will indicate that the policy has been
              changed. For new Users, the change will become effective upon
              posting. For existing Users, if the change is significant, it will
              become effective 30 days after posting. We encourage you to
              periodically review the privacy policy for the latest information
              on our privacy practices.{" "}
            </p>
            <p>
              <strong>12. Contacting Wondershare</strong>
            </p>
            <p>
              If the User needs to contact Wondershare for any reason (including
              to exercise any of its rights in relation to data protection as
              set out above) please contact
              <Link
                to="mailto:rain@yaiyah.xyz"
                ga360location="content_2_buttonLink_21"
              >
                rain@yaiyah.xyz.
              </Link>
            </p>
            <p>
              {" "}
              Wondershare will act on the User’s requests and provide
              information free of charge, except where the requests are
              manifestly unfounded or excessive (in particular because of their
              repetitive nature) in which case Wondershare may charge a
              reasonable fee (taking into account the administrative costs of
              providing the information or communication, or taking the action
              requested), or refuse to act on the request.{" "}
            </p>
            <p>
              <strong>13. YouTube Privacy Policy</strong>
            </p>
            <p>13.1 Privacy when uploading to YouTube</p>
            <p>
              When using the YouTube upload feature, Google’s privacy policy
              applies to media uploaded to YouTube. You can view the Google
              privacy policy here:
              <Link
                to="https://policies.google.com/privacy?hl=en-US"
                style={{
                  color: "#006DFF",
                  textDecoration: "underline",
                }}
                ga360location="content_2_buttonLink_22"
              >
                https://policies.google.com/privacy?hl=en-US
              </Link>
            </p>
            <p>13.2 Revoking FilmoraGo’s access to YouTube Data</p>
            <p>
              You may revoke FilmoraGo’s access to YouTube at any time via the
              Google security settings page at
              <Link
                to="https://security.google.com/settings/security/permissions"
                style={{
                  color: "#006DFF",
                  textDecoration: "underline",
                }}
                ga360location="content_2_buttonLink_23"
              >
                https://security.google.com/settings/security/permissions.
              </Link>
            </p>
            <p>13.3 YouTube Terms of Service</p>
            <p>
              Wondershare will only access and use the viewing, commenting,
              liking and subscription data of Youtube videos and will not store
              or share Google user data.
              <br />
              You can view the YouTube Terms of Service here:
              <Link
                to="https://www.youtube.com/t/terms"
                style={{
                  color: "#006DFF",
                  textDecoration: "underline",
                }}
                ga360location="content_2_buttonLink_24"
              >
                https://www.youtube.com/t/terms
              </Link>
            </p>
            <p>
              <strong>14. Google Privacy Policy</strong>
            </p>
            <p>14.1 Google Drive Services User Data Policy</p>
            <p>
              When using the Google Drive feature, Google's privacy policy
              applies to accessing Google Drive. The personal data we obtain
              through the Google Workspace API will not be used for any purposes
              of developing, improving, or training generalized artificial
              intelligence and/or machine learning models. You can view the
              Google privacy policy here:
              <Link
                to="https://policies.google.com/privacy?hl=en-US"
                style={{
                  color: "#006DFF",
                  textDecoration: "underline",
                }}
              >
                https://policies.google.com/privacy?hl=en-US
              </Link>
              <br />
              For some Wondershare products' generative AI features, the
              personal data we obtain from Google Drive will be transferred to
              third-party AI tools solely for basic and necessary
              functionalities. Such as Wondershare PDFelement AI, the personal
              data we obtain from Google Drive will be transferred to Microsoft
              AI tools, solely for the purpose of implementing the basic and
              necessary editing functionalities required by PDFelement.
            </p>
            <p>14.2 Google API Services User Data Policy</p>
            <p>
              Wondershare Virbo, PDFelement use and transfer to any other app of
              information received from Google APIs will adhere to Google API
              Services User Data Policy, including the Limited Use
              requirements.”
              <br />
              You can view the Google API Services User Data Policy here:
              <Link
                to="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                style={{
                  color: "#006DFF",
                  textDecoration: "underline",
                }}
              >
                https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes
              </Link>
            </p>
            <p>
              <strong>15. Special notes on "Wondershare Virbo"</strong>
            </p>
            <p>
              <b>15.1</b>
              How does Wondershare Virbo collect and use information?
            </p>
            <p>
              In general, we do not collect your personal information when you
              download or use Wondershare Virbo. Below is a description of the
              user permissions we may use or collect and the purposes for their
              use or collection:
            </p>
            <p>
              15.1.1 When you use the video or picture editing function for the
              first time, we need to obtain your local media storage permission,
              which will be used when you select videos or pictures, and save
              videos. Such permission is used solely for you to edit and save
              videos or pictures, and we do not access any of your video or
              picture files.
            </p>
            <p>
              15.1.2 We obtain your personal information when you send feedback
              by email or provide us with an error report. Please note that such
              information only contains your name, email address and survey
              responses. In the above cases, such personal information is used
              solely for the purposes of contacting you and improving our
              products and services. We will not use this type of information
              (your name, email address, etc.) for any other purposes such as
              selling our products or disclosing your personal information to a
              third party for commercial gains.
            </p>
            <p>
              15.1.3 During the secure operation of our software and services,
              we collect your device and log information. Device information
              refers to device model information, which may be stated as "phone
              information" in mobile terminals. This does not mean that
              Wondershare Virbo collects any content or private information you
              have entered. Log information refers to service logs generated
              when you use a service. Device and log information is generally
              used for purposes including identifying standalone devices,
              preventing cheating and determining whether an account is secure.
            </p>
            <p>
              15.1.4 Wondershare Virbo does not currently use targeted
              push/precision marketing features.
            </p>
            <p>
              15.1.5 Why we obtain the local storage permission of the SD card:
              When users edit a video, the application needs to obtain their
              permission to access local pictures and videos to support file
              import and export. Users can decide at their sole discretion
              whether to authorize the application to access local storage space
              using the local storage permission pop-up authorization window.
              Once the user’s authorization is confirmed, the application can
              access the permitted scope of storage space in the relevant
              scenarios: Permission to access pictures and video files in the
              local storage space.
            </p>
            <p>
              15.1.6 If you purchase and use our Customization Service (i.e., AI
              avatar customization services and voice cloning services), we will
              apply to the following:
            </p>
            <p>
              -Algorithm Training. When you purchase our Customization Service,
              you can have yourself or an individual you designate as a model
              (collectively, the“Model”) to shoot Raw Materials (including
              videos, audio, original footage, and other electronic files) and
              provide them to us. Based on the needs of the Customization
              Service, you agree that the Raw Materials you provided should
              contain the Model's portrait, voice, and other personal
              information with biological characteristics, and we will provide
              you with AI avatar image customization, voice cloning, and other
              services by processing and training Raw Materials via relevant
              algorithm technology. Due to algorithm technology and other
              reasons, you hereby authorize and agree that we may transmit the
              aforementioned personal information to a third-party algorithm
              technology service provider (we have the right to dynamically
              update algorithm technology providers according to our business
              needs) for algorithm training to finally form an AI avatar and
              cloned voice. The aforementioned algorithm provider will collect
              and process the aforementioned personal information. If the
              aforementioned Model is not you, you should inform the Model in
              writing of the detailed information collection, ensure that the
              Model is fully and completely aware of and agrees to the
              information collection and processing, and ensure that the Model
              fully and ultimately agrees to our provision of Raw Materials to
              the aforementioned algorithm provider.
            </p>
            <p>
              -Cross-border Data Transfer. The personal information you provide
              to us and the personal information we collect from you will be
              transferred to countries outside your country of residence (such
              as China or the United States), stored or processed in these
              countries/regions. One of our algorithm service providers or
              partners outside the European Economic Area, the UK, or
              Switzerland also processes your personal information. Our
              processing of your personal information provided to us is the
              purpose of providing our Customization Services and fulfill our
              services contracts with you.
            </p>
            <p>
              -Legal basis for our processing. Moreover, the legal basis for us
              processing the aforementioned personal information includes:
              <br />
              A. Fulfilling the contract we have signed with you. After you
              subscribe to our Customization Services, we need to obtain your or
              the relevant Model's personal information under Raw Materials
              containing biological features such as images and voices in order
              to provide you with Customization Services. If you do not provide
              the aforementioned information and Raw Materials, we will not be
              able to provide our Customization Services to you because such
              personal information under Raw Materials is critical and essential
              to creating your AI avatar and cloned voice.
              <br />
              B. Your consent. You consent to us collecting and processing your
              personal information and Raw Materials per the agreement to
              provide services to you.
            </p>
            <p>
              <b>15.2</b>
              Wondershare Virbo personal account information management
            </p>
            <p>15.2.1 Managing personal account information and settings</p>
            <p>
              We provide you with convenient personal information management
              features. You can query and manage all your basic information in
              the Member Center on the settings page.
            </p>
            <p>15.2.2 Canceling your user account</p>
            <p>
              You can request to cancel your Wondershare Virbo account by going
              to [Personal Center] -[System Settings] in the Wondershare Virbo
              mobile client application. Before you cancel your account, we will
              check your information. You acknowledge and understand that the
              act of canceling your account is irreversible, and that once you
              have canceled your account, we will terminate the rights and
              services associated with your account.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
